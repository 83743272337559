const menus = [
  {
    label: 'Qui sommes-nous ?',
    to: '/',
    submenus: [
      { label: 'Création', to: '/#creation' },
      { label: 'Nos valeurs', to: '/#valeurs' },
      { label: 'Equipe', to: '/#equipe' },
    ],
  },
  {
    label: 'Vos pharmacies expertes',
    to: '/nos-pharmacies-expertes',
    submenus: [
      { label: 'Adresses', to: '/nos-pharmacies-expertes#adresses' },
      { label: 'Nos expertises', to: '/nos-pharmacies-expertes#expertises' },
    ],
  },
  {
    label: 'Nos partenaires',
    to: '/nos-partenaires',
    submenus: [
      { label: 'Laboratoires', to: '/nos-partenaires#laboratoires' },
      { label: 'Prestataires', to: '/nos-partenaires#prestataires' },
    ],
  },
  {
    label: 'Nos atouts',
    to: '/nos-atouts',
    submenus: [
      { label: 'Les achats Apothéo', to: '/nos-atouts#achats' },
      { label: `L'indépendance`, to: '/nos-atouts#indépendance' },
      { label: 'Les partenariats', to: '/nos-atouts#partenariats' },
      { label: 'Formations', to: '/nos-atouts#formations' },
      { label: 'Evénements Apothéo', to: '/nos-atouts#evenements' },
    ],
  },
  { label: 'News', to: '/nos-news' },
  {
    label: 'Envie de nous rejoindre ?',
    to: '/nous-rejoindre',
    reversed: true,
  },
];

export { menus };
