import React from 'react';
import menuV0 from '../../icons/menuV0.svg';
import exitMenu from '../../icons/Exitmenu.svg';
import './HeaderNavTrigger.css';

const HeaderNavTrigger = ({ className, open, setOpen }) => {
  return (
    <nav
      className={`HeaderNavTrigger ${className}`}
      onClick={() => setOpen(!open)}
    >
      <img
        className="HeaderNavTrigger__image"
        src={open ? exitMenu : menuV0}
        alt=""
      />
    </nav>
  );
};

export default HeaderNavTrigger;
