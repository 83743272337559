import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css';
import CAROUSEL_DESKTOP_1 from '../../images/CAROUSEL_DESKTOP_1.jpg';
import CAROUSEL_MOBIL_1 from '../../images/CAROUSEL_MOBIL_1.png';

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const imagesDesktop = [
    CAROUSEL_DESKTOP_1,
    CAROUSEL_DESKTOP_1,
    CAROUSEL_DESKTOP_1,
    CAROUSEL_DESKTOP_1,
  ];
  const imagesMobile = [
    CAROUSEL_MOBIL_1,
    CAROUSEL_MOBIL_1,
    CAROUSEL_MOBIL_1,
    CAROUSEL_MOBIL_1,
  ];

  return (
    <div>
      <div className="Carousel__desktop">
        <Slider {...settings}>
          {imagesDesktop.map((image, index) => (
            <img src={image} alt="" key={index} />
          ))}
        </Slider>
      </div>
      <div className="Carousel__mobile">
        <Slider {...settings}>
          {imagesMobile.map((image, index) => (
            <img src={image} alt="" key={index} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
