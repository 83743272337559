import React from 'react';
import APOTHEO_LOGO from '../../images/APOTHEO_LOGO.png';
import BACKGROUND_INTRO from '../../images/BACKGROUND_INTRO.png';
import CLOUD_INTRO from '../../images/CLOUD_INTRO.png';
import './Intro.css';

const Intro = () => {
  return (
    <div
      className="Intro"
      style={{ backgroundImage: `url(${BACKGROUND_INTRO})` }}
    >
      <div className="Intro__container">
        <div className="Intro__container__text">Découvrez</div>
        <img className="Intro__container__image" src={APOTHEO_LOGO} alt="" />
        <div className="Intro__container__text">
          Le groupement de pharmaciens experts au service de votre santé et de
          votre bien-être.
        </div>
      </div>
      <img src={CLOUD_INTRO} alt="" className="Intro__image" />
    </div>
  );
};

export default Intro;
