import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import APOTHEO_LOGO_TEXT from '../../images/APOTHEO_LOGO_TEXT.png';
import MAIL from '../../images/MAIL.png';
import VALWIN_LOGO from '../../images/VALWIN_LOGO.png';
import CLICK from '../../images/CLICK.png';

const Footer = () => (
  <footer className="Footer">
    <div className="Footer__contact">
      <div className="Footer__contact__title">Nous contacter</div>
      <div className="Footer__contact__forms">
        <form
          className="Footer__contact__form"
          action="mailto:contact@apotheo.fr"
          method="GET"
        >
          <button className="Footer__contact__button" type="submit">
            <img src={MAIL} alt="" className="Footer__contact__button__image" />
            <div className="Footer__contact__button__label">
              Envoyer un email
            </div>
          </button>
        </form>
      </div>
    </div>
    <div className="Footer__logo">
      <img src={APOTHEO_LOGO_TEXT} alt="" className="Footer__logo__image" />
    </div>
    <div className="Footer__links">
      <Link to="/cgu" className="Footer__links__link">
        CGU et mentions légales
      </Link>
      <Link to="/plan-du-site" className="Footer__links__link">
        Plan du site
      </Link>
      <img src={VALWIN_LOGO} alt="" className="Footer__links__image" />
    </div>
    <a
      href="https://www.apotheo-intranet.fr"
      target="_blank"
      rel="noopener noreferrer"
      className="Footer__intranet"
    >
      <div className="Footer__intranet__title">
        Vous êtes pharmacien du groupement :
      </div>
      <div className="Footer__intranet__container">
        <div className="Footer__intranet__label">
          Cliquez ici pour vous connecter à l'intranet
        </div>
        <img src={CLICK} alt="" className="Footer__intranet__image" />
      </div>
    </a>
  </footer>
);

export default Footer;
