import React from 'react';
import { NavLink } from 'react-router-dom';
import { menus } from './HeaderConst.js';
import './HeaderNavSmall.css';

const HeaderNavSmall = ({ className }) => {
  return (
    <nav className={`HeaderNavSmall ${className}`}>
      {menus.map((menu) => (
        <>
          <NavLink
            exact
            className={
              menu.reversed
                ? 'HeaderNavSmall__link HeaderNavSmall__link--reversed'
                : 'HeaderNavSmall__link'
            }
            activeClassName="HeaderNavSmall__link--active"
            to={menu.to}
          >
            {menu.label}
          </NavLink>
        </>
      ))}
    </nav>
  );
};

export default HeaderNavSmall;
