import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderNavLarge from './HeaderNavLarge';
import HeaderNavTrigger from './HeaderNavTrigger';
import HeaderNavSmall from './HeaderNavSmall';
import APOTHEO_LOGO from '../../images/APOTHEO_LOGO.png';
import './Header.css';

const Header = () => {
  const [open, setOpen] = useState(false);
  return (
    <header className="Header">
      <Link to="/" className="Header__logo">
        <img src={APOTHEO_LOGO} alt="" />
      </Link>
      <HeaderNavLarge className="Header__large" />
      <HeaderNavTrigger
        className="Header__trigger"
        setOpen={setOpen}
        open={open}
      />
      {open && <HeaderNavSmall className="Header__small" />}
    </header>
  );
};

export default Header;
