import React from 'react';
import TeamMember from '../TeamMember/TeamMember';
import Cyril from '../../images/team/Cyril.png';
import Florian from '../../images/team/Florian.png';
import Katia from '../../images/team/Katia.png';
import Ngan from '../../images/team/Ngan.png';
import './Team.css';

const members = [
  {
    title: 'Ngan Nguyen',
    role: 'Présidente du groupement / Pharmacien',
    image: Ngan,
  },
  {
    title: 'Katia Bac',
    role: 'Chargée de direction',
    image: Katia,
  },
  {
    title: 'Florian Lestir',
    role: 'Responsable comptabilité',
    image: Florian,
  },
  {
    title: 'Cyril Hoebeke',
    role: 'Orthopédiste formateur',
    image: Cyril,
  },
];

const Team = () => {
  return (
    <div className="Team" id="equipe">
      <div className="Team__container">
        <div className="Team__title">Notre équipe</div>
        <div className="Team__paragraph">
          Nos pharmaciens, préparateurs, rayonnistes, stagiaires sont au cœur de
          notre dispositif, depuis la gestion des stocks jusqu’à l’accueil et le
          conseil aux patients. Grâce à leurs compétences, nous assurons au
          quotidien un service de qualité.
        </div>
        <div className="Team__paragraph">
          Nos clients les connaissent, ils s’y attachent et leur font confiance.
          Nous aussi. Ils sont les ambassadeurs de nos valeurs, ils sont
          Apothéo.
        </div>
        <div className="Team__members">
          {members.map((member, index) => (
            <TeamMember
              key={index}
              title={member.title}
              role={member.role}
              image={member.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
