import { asyncComponent } from '@jaredpalmer/after';
import Accueil from './pages/Accueil';
import './App.css';
import './reset.css';

const routes = [
  {
    exact: true,
    path: '/nos-news',
    component: asyncComponent({
      loader: () => import('./pages/News'),
      chunkName: 'News',
    }),
  },
  {
    exact: true,
    path: '/nos-partenaires',
    component: asyncComponent({
      loader: () => import('./pages/Partenaires'),
      chunkName: 'Partenaires',
    }),
  },
  {
    exact: true,
    path: '/nos-pharmacies-expertes',
    component: asyncComponent({
      loader: () => import('./pages/Pharmacies'),
      chunkName: 'Pharmacies',
    }),
  },
  {
    exact: true,
    path: '/nous-rejoindre',
    component: asyncComponent({
      loader: () => import('./pages/Rejoindre'),
      chunkName: 'Rejoindre',
    }),
  },
  {
    exact: true,
    path: '/cgu',
    component: asyncComponent({
      loader: () => import('./pages/Cgu'),
      chunkName: 'Cgu',
    }),
  },
  {
    exact: true,
    path: '/plan-du-site',
    component: asyncComponent({
      loader: () => import('./pages/Plan'),
      chunkName: 'Plan',
    }),
  },
  {
    exact: true,
    path: '/nos-atouts',
    component: asyncComponent({
      loader: () => import('./pages/Atouts'),
      chunkName: 'Atouts',
    }),
  },
  {
    path: '/',
    component: Accueil,
  },
];

export default routes;
