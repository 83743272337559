import React from 'react';
import Value from '../Value/Value';
import './Values.css';

const valuesLeft = [
  {
    title: 'Nous sommes des pharmaciens humains.',
    paragraphs: [
      'Parce qu’en écoutant, en comprenant, en dialoguant, on apprend et on comprend.',
      'Parce que nous sommes persuadés que l’empathie et le professionnalisme sont conjugables.',
    ],
  },
  {
    title: 'Nous sommes des pharmaciens ouverts sur le monde.',
    paragraphs: [
      'Nous concevons nos officines comme des lieux de vie, d’interactions et d’échanges.',
      'Des lieux où on se sent bien. Des lieux pour aller mieux.',
    ],
  },
];

const valuesRight = [
  {
    title: 'Nous sommes des pharmaciens engagés.',
    paragraphs: [
      'Engagés d’abord envers nos clients.',
      'Engagés envers nos quartiers.',
      'Engagés envers l’époque aussi.',
    ],
  },
  {
    title: 'Nous sommes des pharmaciens experts.',
    paragraphs: [
      'Alimentation, sport, dermatologie, gestion du stress, naturopathie… nos expertises sont aussi nombreuses que vos besoins.',
      'Les rendre compréhensibles et accessibles, voilà notre rôle. ',
      'Être à la pointe de la recherche et de ses évolutions, voilà notre devoir.',
    ],
  },
  {
    title: 'Nous sommes des pharmaciens d’aujourd’hui.',
    paragraphs: [
      'Des pharmaciens qui savent comment vivent leurs clients.',
      'Nous mettons l’accent sur les services pour faciliter la vie.',
      'Nous favorisons des prix accessibles pour tous.',
    ],
  },
];

const Values = () => {
  return (
    <div className="Values" id="valeurs">
      <div className="Values__container">
        <div className="Values__title">Nos Valeurs</div>
        <div className="Values__values">
          <div className="Values__left">
            {valuesLeft.map((value, index) => (
              <Value
                key={index}
                title={value.title}
                paragraphs={value.paragraphs}
              />
            ))}
          </div>
          <div className="Values__right">
            {valuesRight.map((value, index) => (
              <Value
                key={index}
                title={value.title}
                paragraphs={value.paragraphs}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
