import React from 'react';
import './Value.css';

const Value = ({ title, image, paragraphs, className }) => {
  return (
    <div className={`Value ${className}`}>
      <div className="Value__title">{title}</div>
      <div className="Value__paragraphs">
        {paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
};

export default Value;
