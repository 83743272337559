import React from 'react';
import './TeamMember.css';

const TeamMember = ({ title, image, role }) => {
  return (
    <div className="TeamMember">
      <div
        className="TeamMember__img"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="TeamMember__infos">
        <h2 className="TeamMember__title">{title}</h2>
        <p className="TeamMember__role">{role}</p>
      </div>
    </div>
  );
};

export default TeamMember;
