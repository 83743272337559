import React from 'react';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';
import Values from '../components/Values/Values';
import Team from '../components/Team/Team';
import Intro from '../components/Intro/Intro';
import History from '../components/History/History';
import Carousel from '../components/Carousel/Carousel';

const Accueil = () => {
  return (
    <>
      <Layout>
        <Seo title="Apothéo - Accueil" description="Apothéo - Accueil" />
        {/* <Carousel /> */}
        <Intro />
        <History />
        <Values />
        <Team />
      </Layout>
    </>
  );
};

export default Accueil;
