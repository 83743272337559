import React from 'react';
import './History.css';

const History = () => {
  return (
    <div className="History" id="creation">
      <div className="History__left">
        <div className="History__title">Création</div>
        <div className="History__text">
          <div>
            C’est en 2017 que Ngan Nguyen concrétise autour d’Apothéo sa vision
            très personnelle de la pharmacie d’aujourd’hui.
          </div>
          <div>
            Forte de son parcours, Ngan Nguyen diffuse un esprit entrepreneurial
            dans tous ses projets. Quand elle envisage la création d’un réseau
            de pharmacies, c’est à travers la relation aux patients que se
            dessinent les premiers axes stratégiques qui donneront naissance à
            Apothéo :
          </div>
          <div className="History__list">
            <div>
              • D’abord le collectif au service des patients : faire peser la
              force d’un réseau dans la recherche d’une offre répondant aux
              besoins du plus grand nombre de patients, et ce au meilleur prix.
            </div>
            <div>
              • Ensuite l’indépendance d’esprit et l’agilité qui en résulte :
              pour toujours mieux anticiper les besoins d’aujourd’hui et de
              demain des patients, en termes d’offres, d’expertises,
              d’accompagnement et de services.
            </div>
          </div>
        </div>
        <div className="History__conclusion">
          <div>Ainsi est né Apothéo. </div>
          <div>Apothéo est plus qu’un simple groupement.</div>
          <div>C’est un regroupement d’énergies de femmes et d’hommes.</div>
          <div>Tous engagés pour les patients.</div>
        </div>
      </div>
      <div className="History__right">
        <iframe
          src="https://www.youtube.com/embed/CRcnyIs1LZU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="History__video"
        ></iframe>
      </div>
    </div>
  );
};

export default History;
