import React from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { menus } from './HeaderConst.js';
import './HeaderNavLarge.css';

const HeaderNavLarge = ({ className }) => {
  return (
    <nav className={`HeaderNavLarge ${className}`}>
      {menus.map((menu, index) => (
        <div key={index} className="HeaderNavLarge__container">
          <NavLink
            exact
            className={
              menu.reversed
                ? 'HeaderNavLarge__link HeaderNavLarge__link--reversed'
                : 'HeaderNavLarge__link'
            }
            activeClassName="HeaderNavLarge__link--active"
            to={menu.to}
          >
            {menu.label}
          </NavLink>
          {menu && menu.submenus && (
            <div className="HeaderNavLarge__sub">
              {menu.submenus.map((submenu, index) => (
                <HashLink
                  key={index}
                  className="HeaderNavLarge__sub__link"
                  to={submenu.to}
                  smooth
                >
                  {submenu.label}
                </HashLink>
              ))}
            </div>
          )}
        </div>
      ))}
    </nav>
  );
};

export default HeaderNavLarge;
